import React from "react"
import styled from "@emotion/styled"
import { FaCircle } from "react-icons/fa"

import Project from "../../components/project"
import Image from "../../components/image"
import { color } from "../../utils"

const Container = styled.article`
  margin: 0;
  padding: 2rem;
  color: ${color.textBackgroundBlack};
  font-size: 1.1rem;
  line-height: 2rem;
  background-color: ${color.backgroundTransparencyBlack};
  opacity: 0;

  animation: fade 1.2s ease-in-out forwards;
  @keyframes fade {
    to {
      opacity: 1;
    }
  }

  @media screen and (min-width: 768px) {
    margin: 3% 10%;
    padding: 2rem 4rem;
    font-size: 1.2rem;
    line-height: 2.5rem;
  }
`
const Title = styled.h1`
  &.title {
    font-size: 2rem;
    text-align: center;
    margin: 2rem 0;
    color: #ffffffa8;
    font-weight: 900;
    font-family: "MuseoModerno", cursive;
  }

  @media screen and (min-width: 768px) {
    &.title {
      font-size: 2rem;
      margin: 1rem 0 3rem 0;
    }
  }
`
const SubTitle = styled.h2`
  &.subtitle {
    font-family: "MuseoModerno", cursive;
    font-size: 1.4rem;
    color: #fb3c76;
  }
`
const TextMain = styled.p`
  text-justify: auto;
  font-size: 1.1rem;
  margin-bottom: 1.7rem;

  span {
    font-size: 1.2rem;
    color: #ff9db1;
  }

  a,
  a:visited {
    color: inherit;
    text-decoration: underline;
  }

  a:hover {
    color: #fb3c76;
  }

  @media screen and (min-width: 768px) {
  }
`
const ImageContainer = styled.div`
  display: grid;

  &.two {
    column-gap: 10px;
    grid-template-columns: 1fr;
  }

  &.one {
    figure {
      margin: 3% auto 5% auto;
    }
    img {
      max-height: 400px;
    }
  }

  figure {
    margin: 3% 3% 6% 3%;
  }

  img {
    object-fit: cover;
    margin: 0;
    padding: 0;
  }

  figcaption {
    font-size: 0.8rem;
  }

  @media screen and (min-width: 768px) {
    &.two {
      grid-template-columns: 1fr 1fr;
    }
  }
`

const Hkdb = () => {
  return (
    <Project title="HKDB">
      <Container>
        <Title className="title">HKDB web application</Title>

        <SubTitle className="subtitle">Type of product:</SubTitle>
        <TextMain>Web application for database interaction</TextMain>
        <SubTitle className="subtitle">Role:</SubTitle>
        <TextMain>
          Design and development of the application (fullstack)
        </TextMain>
        <SubTitle className="subtitle">Tech stack:</SubTitle>
        <TextMain>
          Python, Django, MySQL, HTML, CSS, JavaScript, Bootstrap, jQuery,
          GitLab
        </TextMain>
        <ImageContainer className="one">
          <figure>
            <Image fileName="hkdb-welcome.png" alt="HKDB's initial page" />
            <figcaption>
              1. Welcome page - prior to login (the rest of the website requires
              user authentication).
            </figcaption>
          </figure>
        </ImageContainer>
        <SubTitle className="subtitle">Description of the project:</SubTitle>
        <TextMain>
          This web application -{" "}
          <a
            href="https://hkdb.kaessmannlab.org/"
            target="_blank"
            rel="noreferrer"
          >
            HK database
          </a>{" "}
          - was born from a necessity to manage tissue samples in a research
          facility environment. It was developed for Henrik Kaessmann's
          laboratory, that does a lot of experimental work as well as
          computational. The project got divided into three major areas:
        </TextMain>
        <TextMain>
          -{" "}
          <span>
            First, creating a database schema, based on google sheets that
            already existed, as well as integrating some new ones.
          </span>{" "}
          Due to the nature of the tables, I created a relational database and
          used MySQL for database management. The schema was created through
          Django, that makes the interaction between user interface and database
          much easier and safe (due to its ORM). Throughout the development, new
          tables and fields were added, which was a fairly simple job to do.
        </TextMain>
        <ImageContainer className="one">
          <figure>
            <Image fileName="hkdb-pooling.png" alt="HKDB's pooling page" />
            <figcaption>
              2. Pooling page - gathers information for all libraries and allows
              for simultaneous searching and filtering.
            </figcaption>
          </figure>
        </ImageContainer>
        <TextMain>
          - <span>Second, creating the user interface.</span> This was the
          biggest part of the project. And super fun! With Django, I created
          both backend (using Python) and frontend (using HTML, CSS, JavaScript,
          Bootstrap and jQuery). The app has many functionalities: browsing of
          single tables and aggregated data, adding samples (both single
          instances or in bulk, including the possibility of uploading csv
          files), editing and deleting samples, filtering and downloading
          tables, etc. It also has an user authentication system implemented,
          and security features, including protection against XSS attacks, CSRF
          attacks and SQL injection.
        </TextMain>
        <ImageContainer className="two">
          <figure>
            <Image
              fileName="hkdb-individual.png"
              alt="HKDB's individual page"
            />
            <figcaption>
              3.1. Individual page - information regarding all samples from one
              individual.
            </figcaption>
          </figure>
          <figure>
            <Image
              fileName="hkdb-individuals.png"
              alt="HKDB's table of individuals"
            />
            <figcaption>
              3.2. Table of individuals - all individuals' information in one
              table.
            </figcaption>
          </figure>
        </ImageContainer>
        <ImageContainer className="two">
          <figure>
            <Image
              fileName="hkdb-add-individual.png"
              alt="HKDB's adding feature - one instance"
            />
            <figcaption>
              3.3. Adding samples/individuals can be done with a simple form.
            </figcaption>
          </figure>
          <figure>
            <Image
              fileName="hkdb-add-individuals.png"
              alt="HKDB's adding feature - several instances"
            />
            <figcaption>
              3.4. But also in bulk, in a table with the requested rows, with
              the possibility of uploading csv files.
            </figcaption>
          </figure>
        </ImageContainer>
        <TextMain>
          - <span>Third, importing all previous data from google sheets.</span>{" "}
          This seemed like the easiest part, but it was actually a bit of a
          lengthy operation. I worked with some of the lab staff to analize and
          correct the conflicts that arouse. Since there were thousands of
          samples, it became quite complex. But we did it!
        </TextMain>
        <ImageContainer className="two">
          <figure>
            <Image
              fileName="hkdb-vocabulary.png"
              alt="HKDB's list of controlled vocabulary"
            />
            <figcaption>
              5.1. Page with the lists of controlled vocabulary, used throughout
              the app to avoid redundant entries for certain fields.
            </figcaption>
          </figure>
          <figure>
            <Image fileName="hkdb-notes.png" alt="HKDB's notes' page" />
            <figcaption>
              5.2. There's a "Notes" section where some rules can be set for
              users. A "News" section is also available.
            </figcaption>
          </figure>
          <TextMain>Version control was done using Git/GitLab.</TextMain>
        </ImageContainer>
        <SubTitle className="subtitle">Design:</SubTitle>
        <TextMain>
          For this project I decided to use a Bootstrap theme (
          <a
            href="https://bootswatch.com/simplex/"
            target="_blank"
            rel="noreferrer"
          >
            Simplex
          </a>
          ) in order to have a basis for the layout and be able to focus on
          server-side work. After the MVP was finished, I did some minor
          tweaking.
        </TextMain>
        <TextMain>
          - <span>Colors:</span> White (
          <FaCircle
            style={{ color: "#FFF", fontSize: "0.7rem", margin: "0 4px 0 2px" }}
          />
          #FFF), Red (
          <FaCircle
            style={{
              color: "#D9230F",
              fontSize: "0.7rem",
              margin: "0 4px 0 2px",
            }}
          />
          #D9230F) and Grays (
          <FaCircle
            style={{
              color: "#373A3C",
              fontSize: "0.7rem",
              margin: "0 4px 0 2px",
            }}
          />
          #373A3C,{" "}
          <FaCircle
            style={{ color: "#777", fontSize: "0.7rem", margin: "0 4px 0 2px" }}
          />
          #777).
        </TextMain>
        <TextMain>
          - <span>Typography:</span> The typeface 'Ubuntu' is used in titles and
          buttons, and 'Open Sans' in the main text.{" "}
        </TextMain>
        <TextMain>
          Although the app looks nice, I came to the conclusion that using
          Bootstrap takes away some (too much?) creativity in terms of UI and UX
          development.
        </TextMain>
      </Container>
    </Project>
  )
}

export default Hkdb
